<template>
  <div ref="editor"></div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

export default {
  name: 'RTEditor',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.quill = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: [
          [
            { header: [1, 2, false] }, 'bold', 'italic', 'underline',
            { color: [] }, 'link', { list: 'ordered' }, { list: 'bullet' }, 'clean'
          ]
        ]
      }
    })

    this.setEditorContent(this.modelValue)

    this.quill.on('text-change', () => {
      const content = this.quill.root.innerHTML
      this.$emit('update:modelValue', content)
    })
  },
  methods: {
    setEditorContent (content) {
      const currentSelection = this.quill.getSelection()
      const currentIndex = currentSelection ? currentSelection.index : 0

      this.quill.setContents(this.quill.clipboard.convert({ html: content }))

      this.quill.setSelection(currentIndex)
    }
  },
  watch: {
    modelValue (newValue) {
      this.setEditorContent(newValue)
    }
  }
}
</script>

<style>
.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0rem 0rem;
}

.ql-container {
  height: 120px;
  background-color: #ffffff;
  border-radius: 0rem 0rem 0.375rem 0.375rem;
}

.quill-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
